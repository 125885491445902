@import '../../scss/global.scss';

#site-header {
  height: 6rem;
  background-color: white;

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0;
    max-width: 95vw;

    @media (max-width: 768px) {
      max-width: unset;
      width: 90%;
    }

    .nav-logo {
      width: 12rem;
    }
  }

  .nav-links {
    justify-content: right;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    @media (min-width: $mobile) {
      &.show {
        display: flex;
        font-size: 20px;
        width: 30%;

        .nav-link {
          background-color: white;
          padding: 1rem 2rem;
          border-bottom: 1px solid $primaryCoral;
        }

        .nav-divider {
          display: none;
        }
      }
    }

    @media (max-width: $mobile) {
      background-color: white;
      padding: 2rem;
      border: 1px solid $primaryCoral;
      font-size: 18px;
      justify-content: left;

      .navbar-nav {
        flex-direction: column;
        align-items: flex-start;
      }

      .nav-link {
        padding-bottom: 1rem;
      }
    }

    @media (max-width: 1120px) {
      .nav-link {
        font-size: 14px;
      }
    }

    .nav-divider {
      color: $primaryCoral;
      margin: 0 0 0 1rem;
      font-weight: 900;
      font-size: 1.2rem;
      cursor: default;

      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}
