@import "./global.scss";

.about-page {
  .main-h2 {
    margin-top: 2rem;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    width: 70%;
    margin: 0 auto 3rem;

    @media (max-width: $mobile) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 2rem;
      width: 90%;
    }
  }

  .names-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    margin: 0 auto 4rem;
    width: 80%;

    div {
      border-radius: 15px;
      border: 1px solid black;
      padding: 1.2rem;
      margin: 0;
      text-align: center;
    }
  }
}
