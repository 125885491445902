@import '../../scss/global.scss';

.hero-container {
  height: 40vh;
  width: 100%;
  padding: 0 32px;
  position: relative;
  margin-top: 10vh;

  @media (max-width: $desktop) {
    height: 40vh;
    padding: 0;
    margin-top: 10vh;
  }

  .hero-text-container {
    position: absolute;
    top: 3rem;
    left: 4rem;

    .hero-text {
      text-align: left;
    }

    @media (max-width: $mobile) {
      top: 4rem;
      left: 2rem;
    }

    @media (min-width: $mobile) and (max-width: $desktop) {
      top: 5rem;
      left: 2rem;
    }

    .blue-button {
      font-size: 24px;
      font-weight: bold;
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      margin-top: 3rem;

      @media (max-width: $mobile) {
        font-size: 20px;
        margin-top: 1.4rem;
      }
    }

    h1 {
      font-family: 'Staatliches', sans-serif;
      font-size: 50px;
      font-weight: 400;
      line-height: 48px;
      color: #000;
      text-align: center;

      span {
        font-size: 40px;
      }

      .period {
        font-size: 60px;
        font-family: 'Lato', sans-serif;
      }

      @media (max-width: $mobile) {
        font-size: 42px;
        line-height: 38px;

        span {
          font-size: 22px;
        }

        .period {
          font-size: 32px;
        }
      }

      @media (min-width: $mobile) and (max-width: $desktop) {
        font-size: 3rem;
        line-height: 45px;

        span {
          font-size: 32px;
        }

        .period {
          font-size: 36px;
        }
      }
    }
  }

  .hero-image-overlay {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 90%;
    background: linear-gradient(
      90deg,
      #ef887a 26.04%,
      rgba(179, 221, 242, 0) 100%
    );

    @media (max-width: $mobile) {
      background: linear-gradient(
        135deg,
        #ef887a 26.04%,
        rgba(179, 221, 242, 0) 100%
      );

    }
  }

  .hero-image {
    width: 100vw;
    height: 90%;
    object-fit: cover;
  }
}
