// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

// Colors
$primaryBlue: #00626c;
$blueHover: #008693;
$crystal: #b5d7d9;
$primaryCoral: #ef887a;
$lightCoral: #ffd8cc;
$primaryYellow: #ffce07;
$yellowHover: #ffeb9b;
$lightGray: #f2f1ef;

// Media queries
$mobile: 767px; // max
$desktop: 1023px; // min

// Global styles
#root {
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    padding: 0;
  }
}

// Buttons
.blue-button {
  background-color: $primaryBlue;
  border: $primaryBlue;
  color: white;
  border-radius: 0;
  cursor: pointer;
  font-weight: 900;
  font-size: 1.4vw;
  line-height: 29px;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  display: flex;
  width: 436px;
  height: 64px;
  padding: 1rem;

  &:hover {
    background-color: $blueHover;
    text-decoration: none;
    color: white;
  }

  &:active {
    background: $blueHover;
    text-decoration: none;
    color: white;
  }

  &:focus {
    text-decoration: none;
    background: $blueHover;
    color: white;
  }

  &.disabled,
  &:disabled {
    text-decoration: none;
    cursor: not-allowed;
  }

  @media (max-width: $mobile) {
    height: 3rem;
    font-size: 18px;
    max-width: 16rem;
    //padding: 12px;
  }

  @media (min-width: $mobile) and (max-width: $desktop) {
    max-width: 30vw;
    height: 56px;
    display: flex;
    font-size: 18px;
    padding: 32px;
  }
}

.yellow-button {
  @extend .blue-button;
  background-color: $primaryYellow;
  border: 4px solid black;
  font-size: 26px;
  line-height: 28px;
  padding: 0.6rem 3rem;
  color: black;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    text-decoration: none;
    color: black;
    border: 4px solid black;
  }

  img {
    width: 2rem;
    margin-top: 0.2rem;
  }
}

.white-button {
  @extend .blue-button;
  background-color: white;
  border: 2px solid black;
  font-size: 18px;
  line-height: 28px;
  padding: 0.6rem 3rem;
  color: black;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    text-decoration: none;
    color: black;
    border: 4px solid black;
  }

  img {
    width: 2rem;
  }
}

// Containers
.site-wrap {
  margin: 8vh auto 0;
  padding: 0 32px;

  @media (max-width: $mobile) {
    margin: 12vh auto 0;
    padding: 0;
  }
}

iframe {
  width: 100%;
  border: none;
  margin: 2rem auto;
  padding-top: 1rem;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

// Spacing
.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.center {
  text-align: center;
}

// Text
.main-h2 {
  font-family: 'Staatliches', sans-serif;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.1rem;
  margin-bottom: 32px;
}

.main-h3 {
  font-family: 'Staatliches', sans-serif;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 24px;
}

.link {
  color: $primaryBlue;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: $blueHover;
  }
}

// Mobile screens:
// @media (max-width: $mobile) {
// }

// Tablet screens:
// @media (min-width: $mobile) and (max-width: $desktop) {
// }

// Desktop screens:
// @media (min-width: $desktop) {
// }

//? If tablet and mobile are the same:
// @media (max-width: $desktop) {
// }

// Desktop screens:
// @media (min-width: $desktop) {
// }

//? If tablet and desktop are the same:
// @media (max-width: $mobile) {
// }

// Desktop screens:
// @media (min-width: $mobile) {
// }
