@import "../../scss/global.scss";

.how-we-work {
  background-color: #f2f1ef;
  padding-bottom: 64px !important;

  h2 {
    padding-top: 64px;

    @media (max-width: $mobile) {
      padding-top: 32px;
    }
  }

  .image-header-wrapper {
    position: relative;

    h3 {
      position: absolute;
      top: -1rem;
      left: -1rem;
      font-weight: 400;
      font-family: "Staatliches", sans-serif;
      font-size: 70px;
      line-height: 100px;
      padding: 0 0.4rem;

      @media (max-width: $desktop) {
        display: none;
      }
    }
  }

  .row {
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: $desktop) {
        width: 30vw;
      }

      .circle {
        width: 80vw;
        margin: 0 auto;

        @media (min-width: $desktop) {
          width: 30vw;
          margin-bottom: 2rem;
        }
      }

      p {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        width: 70%;
        margin-bottom: 2rem;
      }

      .blue-button {
        padding: 32px;
        margin-bottom: 2rem;

        @media (min-width: $desktop) {
          padding: 1rem 3.2rem !important;
        }
      }
    }
  }
}
