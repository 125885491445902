@import '../../scss/global.scss';

.footer {
  background-color: $primaryBlue;
  color: white;
  padding: 3.2vw 6vw;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
  width: 100%;

  .footer-container {
    flex-direction: row;
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .attribution {
    font-size: 12px;
    font-weight: 300;
    color: gainsboro;
  }

  .footer-header {
    font-family: 'Staatliches', sans-serif;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;

    &.we-serve {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 400;

      img {
        width: 1.6rem;
      }
    }
  }

  .social-icons {
    a {
      color: white;
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        color: $yellowHover;
      }
    }
  }

  .affiliate {
    p {
      text-align: center;
      margin: 0 0 -0.2rem -1rem;
      font-size: 1.2rem;

      @media (max-width: $mobile) {
        margin-top: 1rem;
      }
    }

    img {
      width: 13rem;
      padding-top: 0.6rem;
    }
  }
}
