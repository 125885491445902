@import '../../scss/global.scss';

.who-we-are {
  text-align: center;
  margin-top: 3vh !important;

  .who-we-are-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    img {
      width: 40%;
      max-width: 20rem;
      margin-bottom: 2rem;
    }

    p {
      margin: auto 0 auto 1rem;
      align-content: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 80%;

      img {
        margin-bottom: 1rem;
        width: 80%;
        max-width: 15rem;
      }
    }
  }

  .blue-button {
    margin: 40px auto 98px;

    @media (max-width: $mobile) {
      margin: 40px auto;
    }
  }
}
