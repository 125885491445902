@import "../../scss/global.scss";

.landing-quote {
  background-color: #b5d7d9;
  text-align: center;
  padding: 64px 32px;

  @media (max-width: $mobile) {
    padding: 32px 16px;
  }

  h3 {
    width: 70%;
    margin: 0 auto 1rem;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    @media (max-width: $mobile) {
      width: 100%;
      font-size: 32px;
      line-height: 40px;
    }
  }

  .quote-attr {
    margin-bottom: 3rem;
    font-style: italic;
    font-size: 22px;

    @media (max-width: $mobile) {
      font-size: 18px;
    }

    a {
      color: #353535;
    }
  }

  h4 {
    margin-bottom: 2rem;
    font-weight: 600;
    font-style: italic;
    font-size: 32px;

    @media (max-width: $mobile) {
      font-size: 24px;
    }
  }

  .yellow-button {
    margin: 0 auto;
  }
}
