@import "../../scss/global.scss";

.site-wrap.landing-news {
  padding: 0 32px 64px;

  .header-container {
    flex-direction: row;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    h2 {
      padding: 0 18px 24px 0;
      font-weight: 400;
      letter-spacing: 0.1px;
      margin: 0;
      text-align: left;

      @media (max-width: $mobile) {
        text-align: center;
        padding: 0;
      }
    }

    a {
      padding: 0 0 0 12px;
      line-height: 60px;
    }
  }

  .news-container {
    width: 100%;
    justify-content: space-around;
    flex-direction: row;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .news-card {
      text-align: center;

      @media (min-width: $mobile) {
        max-width: 30vw;
      }

      @media (min-width: $desktop) {
        width: 24vw;
      }

      a {
        padding-top: 16px;
        text-decoration: none;
        font-size: 18px;

        @media (max-width: $mobile) {
          padding: 24px 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
